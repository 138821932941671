import React from "react"

const BfcWhiteBlankSection = () => {
  return (
    <section className="white-blank-section">
    </section>
  )
}

export default BfcWhiteBlankSection
