import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const BfcUiComponents = () => {
  return (
	<section className="ui-components pb-0">
		<Container>
			<Row>
				<Col xs={11} className="text-center">
					<p className="fst-italic text-white">UI Components</p>
				</Col>
			</Row>
		</Container>
		<Container fluid>
			<Row>
				<Col xs={12} className="px-0 text-right">
					<div className="half-line d-none d-md-block"></div>
                    <StaticImage src="../../../images/case-studies/bfc-payments/ui-components.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1380} height={1151}/>
				</Col>
			</Row>
		</Container>
	</section>
  )
}

export default BfcUiComponents
