import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"


import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import BfcMain from "../../components/case-studies/bfc-payments/bfcMain"
import BfcInfo from "../../components/case-studies/bfc-payments/bfcInfo"
import BfcUserFlows from "../../components/case-studies/bfc-payments/bfcUserFlows"
import BfcSendRequestMoney from "../../components/case-studies/bfc-payments/bfcSendRequestMoney"
import BfcAppNeeded from "../../components/case-studies/bfc-payments/bfcAppNeed"
import BfcBlackBlankSection from "../../components/case-studies/bfc-payments/bfcBlackBlankSection"
import BfcUiComponents from "../../components/case-studies/bfc-payments/bfcUiComponents"
import BfcWhiteBlankSection from "../../components/case-studies/bfc-payments/bfcWhiteBlankSection"
import BfcMoneyTransfer from "../../components/case-studies/bfc-payments/bfcMoneyTransfer"
import BfcCards from "../../components/case-studies/bfc-payments/bfcCards"
import BfcWhiteBlankSection2 from "../../components/case-studies/bfc-payments/bfcWhiteBlankSection2"
import BfcManagingMultiCurrency from "../../components/case-studies/bfc-payments/bfcManagingMultiCurrency"
import BfcBlackBlankSection2 from "../../components/case-studies/bfc-payments/bfcBlackBlankSection2"
import BfcInternational from "../../components/case-studies/bfc-payments/bfcInternational"
import BfcWhiteBlankSection3 from "../../components/case-studies/bfc-payments/bfcWhiteBlankSection3"
import CsBlankSection from "../../components/case-studies/csBlankSection"
import BfcMoreCs from "../../components/case-studies/bfc-payments/bfcMoreCs"
import DirectContact from "../../components/global/directContact"

import csBfcFeaturedImage from "../../../static/case-studies/bfc-payments/bfc-featured-thumbnail.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/bfc-payments.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csBfcFeaturedImage}
      title="BFC Payments App Case Study | UX/UI Design | Fintech"
      description="BFC Payments is Bahrain's trusted bill payment app and money transfer and exchange provider. Our UX team designed an intuitive user experience design for the Fintech app."
    />
  </> 
)



const AshghalsTravelApp = () => {
    return (
      <Layout>
        <Seo
          bid="cs-bfc-payments"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <BfcMain />
        <BfcInfo />
        <BfcUserFlows />
        <BfcSendRequestMoney />
        <BfcAppNeeded />
        <BfcBlackBlankSection />
        <BfcUiComponents />
        <BfcWhiteBlankSection />
        <BfcMoneyTransfer />
        <BfcCards />
        <BfcWhiteBlankSection2 />
        <BfcManagingMultiCurrency />
        <BfcBlackBlankSection2 />
        <BfcInternational />
        <BfcWhiteBlankSection3 />
        <BfcMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default AshghalsTravelApp
