import React from "react"
import BfcLogo from "../../icon/bfcLogo"
import { Col, Container, Row } from "react-bootstrap"

const BfcInfo = () => {
  return (
    <section className="cs-info position-relative">        
        <Container className="position-relative">
            <Row className="pb-md-5 pb-0">
                <Col lg={6}>
                    <h1 className="cs-title px-0 u-section mb-4">Making BFC the trusted choice in Money transfer, Currency exchange and Bill payments.</h1>
                    <p className="cs-sub-text mb-4">The BFC Pay app is an intuitive and trustworthy payment app based out of Bahrain. The app is one of the select few that offers users a gateway to transfer their money, pay their bills, and get access to currency exchange services.</p>
                <Col md={8} className="details-mini d-flex justify-content-between px-0">
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Duration</p>
                        <p className="st-data mb-0">6 Months</p>
                    </div>
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Services</p>
                        <p className="st-data mb-0">UX/UI</p>
                    </div>
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Platform</p>
                        <p className="st-data mb-0">iOS, Android</p>
                    </div>												
                </Col>                
                </Col>
                <Col lg={5} className="offset-lg-1 text-lg-right mt-lg-0 mt-md-5 mt-5">
                    <div className="position-relative">
                       <BfcLogo />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default BfcInfo
