import React from "react"

const BfcBlackBlankSection = () => {
  return (
    <section className="black-blank-section">
    </section>
  )
}

export default BfcBlackBlankSection
