import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcManagingMultiCurrency = () => {
  return (
	<section className="managing-multi-currency py-0">
		<Container>
			<Row className="align-items-end">
				<Col lg ={6} xl={5} className="offset-xl-1">
					<h2 className="sub-title mb-4">Managing multi-currency travel cards became effortless</h2>
					<p>Users can generate and link existing cards without much hassle in the BFC Pay app.</p>

					<p>We designed the interface in such a way that users can quickly identify if their cards are active or inactive.</p>
				</Col>
				<Col lg={6} xl={5} className="offset-xl-1">
					<div className="bfc-mobile-steps mmc d-flex">
						<div className="steps fst-italic  mr-3">Add Money</div>
						<div className="steps fst-italic  mr-3">Money Transfer</div>
						<div className="steps fst-italic">Money Requests</div>
					</div>					
				</Col>
				<Col xs={12} className="mt-3 pt-3">
                    <StaticImage src="../../../images/case-studies/bfc-payments/bfc-managing-mc.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={2161} height={1862}/>
				</Col>
			</Row>
		</Container>
	</section>       
  )
}

export default BfcManagingMultiCurrency
