import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcCards = () => {
  return ( 
	<section className="bfc-cards pb-0">
		<Container>
			<Row>
				<Col lg={6} className="offset-lg-1">
					<h2 className="sub-title text-white">
					BFC <br />
					Debit,<br />
					Multicurrency <br />
					Cards
					</h2>
				</Col>
				<Col lg={11} className="offset-lg-1 ps-lg-0 mt-5 pt-5">
                    <StaticImage src="../../../images/case-studies/bfc-payments/bfc-cards-image.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1159} height={456}/>
				</Col>
			</Row>
		</Container>
	</section>      
  )
}

export default BfcCards
