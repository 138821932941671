import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcMoneyTransfer = () => {
  return (
	<section className="bfc-money-transfer">
		<Container>
			<Row className="justify-content-center">
				<Col lg={5} className="text-center">
					<h2 className="sub-title text-white">Money Transfer now takes only 3 steps</h2>
				</Col>
				<Col xs={12} className="mt-md-5 pt-md-4 pt-0 mt-0">
                    <StaticImage src="../../../images/case-studies/bfc-payments/money-transfer-steps.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1306} height={737}/>
				</Col>
			</Row>
			<Row className="mt-5 pt-md-5 pt-0 justify-content-between">
				<Col md={6}>
					<div className="bfc-mobile-steps d-flex">
						<div className="steps fst-italic text-white mr-3">Choose beneficiary</div>
						<div className="steps fst-italic text-white mr-3">Confirm transfer</div>
						<div className="steps fst-italic text-white">Transaction successful</div>
					</div>
				</Col>
				<Col md={1} className="d-none d-md-block">
					<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 1.80762V53.4999H52.6923" stroke="white" stroke-width="0.5"/>
						<path d="M1 53.5L53.5 1" stroke="white" stroke-width="0.5" stroke-linecap="round"/>
					</svg>
				</Col>				
			</Row>
		</Container>

		<Container fluid className="mt-3">
			<Row>
				<Col xs={12} className="px-0">
					<div className="half-line d-none d-md-block"></div>
				</Col>
			</Row>
		</Container>		
		
	</section>    
  )
}

export default BfcMoneyTransfer
