import React from "react"

const CsBlankSection = () => {
  return (
    <section className="blank-section">       
    </section>
  )
}

export default CsBlankSection
