import React from "react"

const BfcWhiteBlankSection2 = () => {
  return (
    <section className="white-blank-section-2">
    </section>
  )
}

export default BfcWhiteBlankSection2
