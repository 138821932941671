import React from "react"

const BfcWhiteBlankSection3 = () => {
  return (
    <section className="white-blank-section-3">
    </section>
  )
}

export default BfcWhiteBlankSection3
