import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const BfcUserFlows = () => {
  return (
	<section className="user-flows position-relative">
		<Container>
			<Row className="justify-content-center">
				<Col lg={7} className="text-center d-flex align-items-center flex-column">
					<h2 className="sub-title text-white mb-4">Smooth user flows to kick the speed up a notch</h2>
					<p className="text-white col-10">BFC Pay app offers an instinctive user interface to the customers.
						The goal was to make the app as intuitive and self-explanatory as possible.</p>
				</Col>
			</Row>
		</Container>
		<Container fluid>
			<div className="top-left-svg">
				<svg width="196" height="392" viewBox="0 0 196 392" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M54.6425 62.322L79.5849 1.00076" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M177.45 233.062L152.859 293.519" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<rect x="-176.804" y="159.429" width="251.453" height="251.453" transform="rotate(-22.866 -176.804 159.429)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M-152.477 98.865L79.5846 1.00077L177.449 233.062" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<rect x="-116.347" y="184.02" width="159.151" height="159.151" transform="rotate(-22.866 -116.347 184.02)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M-85.0195 170.592L-36.3436 286.015L79.0795 237.339" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
				</svg>
			</div>
			<div className="top-right-svg">
				<svg width="126" height="423" viewBox="0 0 126 423" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M33.291 180.937L33.9725 246.201" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<rect x="-0.282827" y="0.00295368" width="251.453" height="251.453" transform="matrix(-0.714452 -0.699684 -0.699684 0.714452 389.644 242.285)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M389.443 177.218L209.507 1.00031L33.2892 180.937" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<rect x="-0.282827" y="0.00295368" width="159.151" height="159.151" transform="matrix(-0.714452 -0.699684 -0.699684 0.714452 324.38 242.967)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M300.369 219.176L212.722 308.673L123.224 221.026" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
				</svg>

			</div>
			<div className="bottom-left-svg">
				<svg width="125" height="335" viewBox="0 0 125 335" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="-53.6693" cy="136.454" r="68.1405" transform="rotate(20.1793 -53.6693 136.454)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M13.6542 126.061C1.55981 91.1015 -36.6062 72.5734 -71.592 84.677C-106.578 96.7806 -125.135 134.932 -113.041 169.892" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<circle cx="-53.6689" cy="136.454" r="106.107" transform="rotate(20.1793 -53.6689 136.454)" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M51.5324 122.52C61.5736 185.675 22.8134 244.329 -35.0409 253.527C-92.8952 262.725 -147.935 218.985 -157.977 155.83" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
				</svg>
			</div>
			<div className="bottom-right-svg">
				<svg width="152" height="361" viewBox="0 0 152 361" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="146.645" cy="146.645" r="94.0718" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M228.867 101.152C196.573 61.6436 138.34 55.8153 98.8007 88.1347C59.2617 120.454 53.3891 178.682 85.6839 218.191" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<circle cx="146.645" cy="146.645" r="146.445" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
					<path d="M276.225 78.5438C319.279 155.537 297.004 249.924 226.473 289.364C155.942 328.804 63.8639 298.361 20.8105 221.368" stroke="white" stroke-opacity="0.6" stroke-width="0.4"/>
				</svg>

			</div>			
			<Row>
				<Col xs={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/bfc-payments/user-flows.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={1440} height={1000}/>
				</Col>
			</Row>
		</Container>
	</section>    
  )
}

export default BfcUserFlows