import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcInternational = () => {
  return (
	<section className="bfc-international py-0">
		<Container>
			<Row>
				<Col lg={6} className="offset-lg-1">
					<h2 className="sub-title mb-4 text-white">
					Ensured a super-easy 
					flow in the International
					Money Transfer system
					</h2>
					<p className="text-white">The interface saved a lot of time and removed confusion by informing users about the exact details of their accounts. This was achieved with a dashboard feature that made it super easy to switch between multiple currencies.</p>
				</Col>
				<Col lg={11} className="offset-lg-1 mt-md-5 mt-2">
                    <StaticImage src="../../../images/case-studies/bfc-payments/bfc-country.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1226} height={73}/>
				</Col>

				<Col xs={12} className="mt-5 pt-md-5 pt-0 text-center">
                    <StaticImage src="../../../images/case-studies/bfc-payments/bfc-coutries-app.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1306} height={737}/>
				</Col>
			</Row>
		</Container>
	</section>        
  )
}

export default BfcInternational
