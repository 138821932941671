import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcAppNeeded = () => {
  return (
    <section className="bfc-app-needed pb-0 position-relative">
        <Container>
            <Row className="justify-content-center">
                <Col lg={4} className="text-center">
                    <h2 className="sub-title">One app, that’s all you need</h2>
                </Col>
            </Row>
            <Row className="mt-md-5">
                <Col xs={12} className="text-center">
                    <StaticImage src="../../../images/case-studies/bfc-payments/bfc-app-img.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={424} height={856}/>
                </Col>
            </Row>
        </Container>
        <div className="four-lines">
            <svg width="1920" height="283" viewBox="0 0 1440 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                <line x1="-57" y1="0.8" x2="1513" y2="0.8" stroke="black" stroke-width="0.4"/>
                <line x1="-57" y1="188.8" x2="1513" y2="188.8" stroke="black" stroke-width="0.4"/>
                <line x1="-57" y1="94.8" x2="1513" y2="94.8" stroke="black" stroke-width="0.4"/>
                <line x1="-57" y1="282.8" x2="1513" y2="282.8" stroke="black" stroke-width="0.4"/>
                </g>
            </svg>

        </div>
    </section>
  )
}

export default BfcAppNeeded
