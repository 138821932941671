import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const BfcMain = () => {
  return (
    <div className="cs-header d-flex align-items-end">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9} md={10} className="position-relative">
                    <div className="hero-image text-center">
                        <StaticImage src="../../../images/case-studies/bfc-payments/bfc-hero-image.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={1275} height={937}/>
                    </div>
                </Col>
            </Row>
        </Container>       
    </div>
  )
}

export default BfcMain
