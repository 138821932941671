import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BfcSendRequestMoney = () => {
  return (
	<section className="send-req-money">
		<Container>
			<Row>
				<Col lg={11} className="text-center">
					<p className="fst-italic text-white">Send / Request  Money</p>
				</Col>
			</Row>
		</Container>
		<Container fluid>
			<Row>
				<Col xs={12} className="px-0">
					<div className="half-line d-none d-md-block mb-4"></div>
					<StaticImage src="../../../images/case-studies/bfc-payments/bfc-send-request-money.png" alt="BFC Payments WowMakers Case study Header" placeholder="blurred" className="img-fluid cs-image-min-width" width={1293} height={958}/>
				</Col>
			</Row>
		</Container>
	</section>    
  )
}

export default BfcSendRequestMoney
