import React from "react"

const BfcBlackBlankSection2 = () => {
  return (
    <section className="black-blank-section-2">
    </section>
  )
}

export default BfcBlackBlankSection2
